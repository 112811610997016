import type { EventStream, Event } from '../../elm/EventStream/js/EventStream';
import * as CommonTypes from './CommonTypes';
import * as Segment from './Segment';
import { ProductList } from '..';
import * as ProductView from './product-view';

interface ProductClickSegmentPayload extends ProductView.SegmentPayload {
  position: number,
  product_list_size: number,
  product_list_name: string
}

export function segmentPayloadFromEvent(
  event: Event,
  exchangeRateToAUD: CommonTypes.CurrencyConversionRate
): ProductClickSegmentPayload {
  const productList = event.eventData.productList as ProductList;
  return {
    ...ProductView.segmentPayloadFromEvent(event, exchangeRateToAUD),
    position: productList.position,
    product_list_size: productList.count,
    product_list_name: productList.group

  };
}

export default function (eventStream: EventStream) {
  eventStream.addListener('product.list.click', (event: Event) => {
    Segment.push(
      'Product Clicked',
      segmentPayloadFromEvent(event, window.dataLayerInitialValues.exchange_rate_to_aud)
    );
  });
}
