import type { EventStream, Event } from '../../elm/EventStream/js/EventStream';
import * as Segment from "./Segment"

interface CheckoutStepCompletedPayload {
  checkout_id: string
  step: number,
  field_name: string,
  shipping_method: string | null,
  shipping_to_country_code: string | null,
  payment_method: string | null
}

export function segmentPayloadFromEvent(
  event: Event,
  checkout_id: string,
): CheckoutStepCompletedPayload {
  const fieldName = event.eventData.fieldName;
  const step = event.eventData.step;
  const shipping_method = sessionStorage.getItem("shipping_method")
  const shipping_to_country_code = sessionStorage.getItem("shipping_to_country_code")
  const payment_method = sessionStorage.getItem("payment_method")

  return {
    checkout_id: checkout_id,
    step: step,
    field_name: fieldName,
    shipping_method: shipping_method || "",
    shipping_to_country_code: shipping_to_country_code || "",
    payment_method: payment_method || "",
  }
}

let removeValuesFromSessionStorageOnLoad = (fieldType : string) : void => {
  // This will remove "payment_method" from sessionStorage if user reloads the page.
  if (fieldType == "initial") {
    sessionStorage.removeItem("payment_method")
    sessionStorage.removeItem("shipping_method")
  }
}

export default function (eventStream: EventStream) {
  if (window.location.pathname != "/checkout") return

  // Get the payment method from event and store it in session storage.
  eventStream.addListener('checkout.form.payment_method', (event: Event) => {
    sessionStorage.setItem("payment_method", event.eventData.paymentMethod)
  })

  eventStream.addListener('generic.form.field', (event: Event) => {
    const fieldId = event.eventData.fieldId;
    const fieldEventType = event.eventData.fieldEventType;
    const fieldValid = event.eventData.valid;
    const checkout_id = window.dataLayerInitialValues.user.continuation_token

    switch(fieldId) {
    case "shipping_service":
      removeValuesFromSessionStorageOnLoad(fieldEventType)

      // Only trigger this event when page load and when changing the value (page load triggers an "initial" event)
      if (fieldEventType == "initial" || fieldEventType == "change") {
        sessionStorage.setItem("shipping_method", event.eventData.value)

        Segment.push(
          'Checkout Step Completed',
          segmentPayloadFromEvent(event, checkout_id)
        );
      }
      break;
    case "shipping_country":
      if (fieldEventType == "initial" || fieldEventType == "change") {
        sessionStorage.setItem("shipping_to_country_code", event.eventData.value)
      }
      break;
    default:
      removeValuesFromSessionStorageOnLoad(fieldEventType)

      // Only triggers on initial load if field is valid and filled out, or on blur if field is valid
      if (fieldEventType == "initial" || fieldEventType == "blur" && fieldValid) {
        Segment.push(
          'Checkout Step Completed',
          segmentPayloadFromEvent(event, checkout_id)
        );
      }
    }
  });
  // Clear our session storage when the user complete the order.
  eventStream.addListener("checkout.finalization", (event: Event) => {
    sessionStorage.removeItem("shipping_method")
    sessionStorage.removeItem("payment_method")
  })
}
