import type { EventStream, Event } from '../../elm/EventStream/js/EventStream';
import * as Segment from './Segment';

interface ContentExpandedPayload {
  id: string;
}

export function segmentPayloadFromEvent(event: Event): ContentExpandedPayload {
  return {
    id: event.eventData.id,
  };
}

export default function (eventStream: EventStream) {
  eventStream.addListener('generic.tab.expand', (event: Event) => {
    Segment.push('Content Expanded', segmentPayloadFromEvent(event));
  });
}
