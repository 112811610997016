/* global window document  */
import { Elm } from '../../javascript/elm-apps.js';
import * as EventStream from './js/EventStream';
import SubscribeSentryLogger from '../Common/Error/SubscribeSentryLogger';
import type BatchedDataLayer from '../../javascript/lib/Batch/BatchedDataLayer';
import { BellroyError } from '../Common/Error/Types';
import { PortFromElm, PortToElm } from '../Common/ElmPorts';

type EventStreamElmApp = {
  ports: {
    eventStreamActions: PortToElm<EventStream.Action>;
    eventStreamResponses: PortFromElm<EventStream.Response>;
    error: PortFromElm<BellroyError>;
  };
};

export default function (batchedDataLayer: BatchedDataLayer) {
  const $scriptFlags = document.getElementById('EventStreamFlags');

  let flags = {
    version: '1.0.0',
    ab_tests: {},
  };

  if ($scriptFlags) {
    const parsedFlags = JSON.parse($scriptFlags.innerHTML);
    $scriptFlags.parentElement?.removeChild($scriptFlags);
    if (parsedFlags) {
      flags = Object.assign(flags, parsedFlags);
    }
  }

  const app: EventStreamElmApp = Elm.EventStream.Main.init({
    node: document.getElementById('EventStreamApp'),
    flags,
  });
  const eventStream = new EventStream.EventStream(
    Object.assign(app.ports, {
      dataLayer: batchedDataLayer,
    })
  );

  SubscribeSentryLogger(app.ports.error);

  window.EventStream = eventStream;

  return eventStream;
}
