/* global window HTMLElement */
import { EventStream, Event } from '../elm/EventStream/js/EventStream';

export default function (eventStream: EventStream) {

    const url = document.location.pathname;

    if (!url.includes("/products")) {
        return;
    }
    if (url.includes("/products/category")) {
        return;
    }

    const recentlyViewedLocal = window.localStorage && window.localStorage.getItem('recentlyViewed');
    let recentlyViewedStyles = recentlyViewedLocal ? [...recentlyViewedLocal.split(',')] : [];

    eventStream.addListener("product.view", (e: Event) => {
        const style = e.eventData?.product?.style;
        recentlyViewedStyles = recentlyViewedStyles.filter(x => x !== style);
        recentlyViewedStyles.unshift(style);
        window.localStorage.setItem('recentlyViewed', recentlyViewedStyles.slice(0, maximumNumberOfProductsToStore).join(","));
    })
}

const maximumNumberOfProductsToStore = 10;
