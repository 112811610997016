import type { EventStream, Event } from '../../elm/EventStream/js/EventStream';
import * as CommonTypes from './CommonTypes';
import * as Segment from './Segment';
import * as Tracking from './EventStreamLineItem';
import * as CurrencyCode from './CurrencyCode';
import * as Bellroy from '..';

interface SegmentPayload {
  barcode: string;
  brand: string;
  canonical_url: string;
  cart_id: string;
  category: string;
  dimensions: { [_: string]: string[] };
  human_readable_key: string;
  image_url: string | undefined;
  name: string;
  price: CommonTypes.PriceInUnits;
  price_details: {
    aud: CommonTypes.Money;
    local: CommonTypes.Money;
    formatted: string;
    price_channel: string;
    price_group: string;
  };
  product_id: string;
  quantity: number;
  sku: string;
  url: string;
}

export function segmentPayloadFromEvent(
  dataLayerInitialValues: typeof window.dataLayerInitialValues,
  event: Event
): SegmentPayload {
  // GTM equivalent: DLV - ecommerce.add.products.0
  const addedProductLineItem: Tracking.ProductLineItem = event.eventData.lineItem;
  const addedProduct: Bellroy.Product = addedProductLineItem.item;
  const priceInUnits = CommonTypes.centsToUnits(addedProduct.price.price_in_cents);

  return {
    ...addedProduct,
    brand: addedProductLineItem.brand,
    cart_id: dataLayerInitialValues.user.continuation_token,
    category: addedProduct.dimensions['product_type']?.[0] ?? '',
    image_url: addedProductLineItem.thumbnailImageUrl,
    price: priceInUnits,
    price_details: {
      aud: CommonTypes.moneyifyAUD(
        CommonTypes.convertedUnits(priceInUnits, dataLayerInitialValues.exchange_rate_to_aud)
      ),
      local: CommonTypes.moneyify(
        priceInUnits,
        CurrencyCode.parse(addedProduct.price.currency_code)
      ),
      formatted: addedProduct.price.formatted,
      price_channel: 'bellroy.com',
      price_group: addedProduct.price.price_group,
    },
    product_id: addedProduct.sku,
    quantity: addedProductLineItem.quantity,
    url: addedProduct.canonical_url,
  };
}

export default function (eventStream: EventStream) {
  eventStream.addListener('cart.add_line_item', (event: Event) => {
    if (event.eventData.lineItem.lineItemType == 'product') {
      Segment.push('Product Added', segmentPayloadFromEvent(window.dataLayerInitialValues, event));
    }
  });
}
