export type PortToElm<T> = {
  send: (arg: T) => void;
};

export type PortFromElm<T> = {
  subscribe: (callback: (result: T) => void) => void;
};

type PendingRequest<T> = {
  callback: (arg: T) => void;
  promise: Promise<T>;
};

// When the returned function is called, it sends its argument to `portToElm`
// and produces a Promise. The next time `portFromElm` is called with some
// argument, the Promise resolves with that argument.
//
// While the resulting Promise is waiting to be resolved, any further calls to
// the returned function will produce the very same pending Promise.
//
// Warning: This function does not attempt to synchronise the ports, so it is
// possible for Elm to call `portFromElm` before handling `portToElm`. If such
// synchronisation is required, it must be implemented within the Elm model.
export const promiseFromElmPorts = <Argument, Result>(
  portToElm: PortToElm<Argument>,
  portFromElm: PortFromElm<Result>
): ((argument: Argument) => Promise<Result>) => {
  let pendingRequest: PendingRequest<Result> | null = null;
  portFromElm.subscribe((result) => {
    if (pendingRequest) {
      pendingRequest.callback(result);
    }
  });
  return (argument: Argument) => {
    if (!pendingRequest) {
      // Promise synchronously calls its argument during construction, so
      // `callback` is guaranteed to be initialised. TypeScript flow analysis
      // cannot see this but it is reassured by the non-null assertion operator
      // (`!`).
      let callback!: (result: Result) => void;
      const promise = new Promise<Result>((resolve) => {
        callback = (result) => {
          pendingRequest = null;
          resolve(result);
        };
      });
      pendingRequest = { callback, promise };
    }
    // XXX: `send` causes Elm to step, which can cause the Promise to resolve
    // and `pendingRequest` to be cleared.
    let promise = pendingRequest.promise;
    portToElm.send(argument);
    return promise;
  };
};

// Takes an outgoing Elm port.
// Returns a Promise. The next time `port` is called with some argument, the
// Promise resolves with that argument.
export const promiseFromElmPort = <Result>(port: PortFromElm<Result>): Promise<Result> => {
  return new Promise<Result>((resolve) => {
    port.subscribe((result) => {
      resolve(result);
    });
  });
};
