import { onCLS, onINP, onLCP, Metric } from 'web-vitals';
import BatchedDataLayer from './lib/Batch/BatchedDataLayer';

export default function (batchedDataLayer: BatchedDataLayer) {
  function sendToAnalytics(metric: Metric) {
    batchedDataLayer.push({
      event: 'coreWebVitals',
      webVitalsMeasurement: {
        delta: metric.delta,
        deltaRounded: Math.round(metric.name === 'CLS' ? metric.delta * 1000 : metric.delta),
        id: metric.id,
        name: metric.name,
        value: metric.value,
        valueRounded: Math.round(metric.name === 'CLS' ? metric.value * 1000 : metric.value),
      },
    });
  }

  onCLS(sendToAnalytics);
  onINP(sendToAnalytics);
  onLCP(sendToAnalytics);
}
