export enum CurrencyCode {
  AED = 'AED',
  AUD = 'AUD',
  CAD = 'CAD',
  CNY = 'CNY',
  EUR = 'EUR',
  GBP = 'GBP',
  HKD = 'HKD',
  JPY = 'JPY',
  KRW = 'KRW',
  MYR = 'MYR',
  NZD = 'NZD',
  SGD = 'SGD',
  TWD = 'TWD',
  USD = 'USD',
}

export function parse(rawCurrencyCode: String): CurrencyCode {
  switch (rawCurrencyCode.toUpperCase()) {
    case 'AED': {
      return CurrencyCode.AED;
    }
    case 'AUD': {
      return CurrencyCode.AUD;
    }
    case 'CAD': {
      return CurrencyCode.CAD;
    }
    case 'CNY': {
      return CurrencyCode.CNY;
    }
    case 'EUR': {
      return CurrencyCode.EUR;
    }
    case 'GBP': {
      return CurrencyCode.GBP;
    }
    case 'HKD': {
      return CurrencyCode.HKD;
    }
    case 'JPY': {
      return CurrencyCode.JPY;
    }
    case 'KRW': {
      return CurrencyCode.KRW;
    }
    case 'MYR': {
      return CurrencyCode.MYR;
    }
    case 'NZD': {
      return CurrencyCode.NZD;
    }
    case 'SGD': {
      return CurrencyCode.SGD;
    }
    case 'TWD': {
      return CurrencyCode.TWD;
    }
    case 'USD': {
      return CurrencyCode.USD;
    }
    default: {
      throw `Unknown currency code ${rawCurrencyCode}`;
    }
  }
}

export function render(currencyCode: CurrencyCode): string {
  const s = CurrencyCode[currencyCode];
  if (s) {
    return s;
  } else {
    throw `Unknown currency code ${s}`;
  }
}

export function denominator(currencyCode: CurrencyCode): number {
  switch (currencyCode) {
    case CurrencyCode.AED: {
      return 100;
    }
    case CurrencyCode.AUD: {
      return 100;
    }
    case CurrencyCode.CAD: {
      return 100;
    }
    case CurrencyCode.CNY: {
      return 100;
    }
    case CurrencyCode.EUR: {
      return 100;
    }
    case CurrencyCode.GBP: {
      return 100;
    }
    case CurrencyCode.HKD: {
      return 100;
    }
    case CurrencyCode.JPY: {
      return 1;
    }
    case CurrencyCode.KRW: {
      return 1;
    }
    case CurrencyCode.MYR: {
      return 100;
    }
    case CurrencyCode.NZD: {
      return 100;
    }
    case CurrencyCode.SGD: {
      return 100;
    }
    case CurrencyCode.TWD: {
      return 100;
    }
    case CurrencyCode.USD: {
      return 100;
    }
    default: {
      throw `Unknown currency code ${currencyCode}`;
    }
  }
}
