/* global window */
import { DictString } from '../DictString';

export class I18n {
  public translations: DictString<string>;

  constructor(translations: DictString<string>) {
    this.translations = translations;
  }

  exists(str: string): boolean {
    return (typeof this.translations[str] !== 'undefined');
  }

  count(number: number): string {
    if (number === 1) {
      return 'one';
    } else if (number >= 1) {
      return 'other';
    }
    return 'zero';
  }

  t(string: string, interpolate: DictString<string> | undefined): string | null {
    if (!this.exists(string)) {
      return null;
    }
    let result = this.translations[string] ?? null;
    if (result && typeof interpolate !== 'undefined') {
      Object.keys(interpolate).forEach((key) => {
        const pattern = new RegExp(`%[{(]${key}[})]`);
        result = result!.split(pattern).join(interpolate[key]);
      });
      result = result.replace(/%[{(]([a-z]+)[})]/gi, '');
    }

    return result;
  }

  a(arr: string[], interpolate: DictString<string>): string {
    const str = arr.shift();
    if (!str) { return ''; }
    const t = this.t(str, interpolate);
    if (t === null) {
      return this.a(arr, interpolate);
    }
    return t;
  }

  responsive(array: string | any[], key: string | number): string | null {
    if (typeof array === 'string') {
      return this.t(array, undefined);
    }
    if (this.exists(array[key])) {
      return this.translations[array[key]] ?? null;
    }
    for (let i = array.length - 1; i >= 0; i -= 1) {
      const str = array[i];
      if (this.exists(str)) {
        return this.translations[str] ?? null;
      }
    }
    return null;
  }
}

export default async function() {
  const translations = await fetch(
    `/api/v1/cms/translations.json?locale=${window.I18n.locale}`
  );
  return new I18n(await translations.json());
}
