import { v4 as uuid } from 'uuid';

const DEFAULT_TIMEOUT_MS = 500;

type Transaction = {
  transactionId: string,
  resolve: any,
  reject: any,
  data: any
}

interface TransactionResult {
  transactionId?: string,
  result: any
}

export default class Transactions {
  private onUnresolved: (_: TransactionResult) => void
  private transactions: Transaction[] = [];

  constructor({ onUnresolved = () => { } }: { onUnresolved: (_: TransactionResult) => void }) {
    this.onUnresolved = onUnresolved;
  }

  private uniqueId() {
    const id = uuid();
    return !this.transactions.find((a) => a.transactionId === id) ? id : this.uniqueId();
  };

  run<Input extends object, Output extends object>(func: (_: Input & { transactionId: any; }) => void, data: Input, timeout = null) {
    return new Promise<{ result: Output, transaction: any }>((p_resolve, reject) => {
      const transactionId = this.uniqueId();

      const timer = setTimeout(
        () => {
          reject();
        },
        timeout === null ? DEFAULT_TIMEOUT_MS : timeout
      );

      const resolve = ({ result, transaction }) => {
        clearTimeout(timer);
        p_resolve({
          result,
          transaction,
        });
      };

      this.transactions.push({
        transactionId,
        resolve,
        reject,
        data,
      });
      func(
        {
          ...data,
          transactionId,
        }
      );
    });
  }

  resolve(obj: TransactionResult) {
    const { transactionId = false, result = null } = obj;
    if (transactionId !== false) {
      const transaction = this.transactions.find((a) => a.transactionId === transactionId);
      if (transaction) {
        if (typeof result.error !== 'undefined') {
          transaction.reject(result.error, transaction);
        } else {
          transaction.resolve({
            result,
            transaction,
          });
        }
        this.transactions = this.transactions.filter((a) => a.transactionId !== transactionId);
        return;
      }
    }

    this.onUnresolved(obj);
  }

  reject({ transactionId, result = null }) {
    const transaction = this.transactions.find((a) => a.transactionId === transactionId);
    if (transaction) {
      transaction.reject({
        result,
        transaction,
      });
    }
    this.transactions = this.transactions.filter((a) => a.transactionId !== transactionId);
  }
}
