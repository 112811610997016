import type { EventStream, Event } from '../../elm/EventStream/js/EventStream';
import * as CommonTypes from './CommonTypes';
import * as Segment from './Segment';
import * as Tracking from './EventStreamLineItem';
import { Product } from '..';
import * as CurrencyCode from './CurrencyCode';

interface GoogleAnalyticsPayload {
  color?: any;
  currency?: any;
  formattedPrice?: string;
  item: Tracking.ProductLineItem;
  name: any;
  price: any;
  productUrl: any;
  rfid?: boolean;
  size?: any;
  sku: any;
  variant?: string;
}

export function googleAnalyticsPayloadFromEvent(event: Event): GoogleAnalyticsPayload {
  const { product, currencyCode } = event.eventData;

  let formattedPrice = '' + CommonTypes.centsToUnits(product.price);
  try {
    formattedPrice = new Intl.NumberFormat('en-EN', {
      style: 'currency',
      currency: currencyCode,
    }).format(CommonTypes.centsToUnits(product.price));
  } catch (e) { }

  return {
    price: product.price,
    formattedPrice: formattedPrice,
    color: product.colour,
    currency: currencyCode,
    item: product.item,
    name: product.name,
    rfid: product.material.indexOf('rfid') >= 0,
    size: product.size,
    sku: product.sku,
    variant: [product.material, product.size].join('_'),
    productUrl: product.productUrl,
  };
}

function pushToGoogleAnalytics(event: Event) {
  window.dataLayer.push({
    hitType: 'change-product',
    event: 'change-product',
    product: googleAnalyticsPayloadFromEvent(event),
  });
}

export interface SegmentPayload {
  barcode: string;
  brand: string;
  canonical_url: string;
  category: string;
  currency: CurrencyCode.CurrencyCode;
  dimensions: { [_: string]: string[] };
  image_url: null;
  human_readable_key: string;
  name: string;
  price: CommonTypes.PriceInUnits;
  price_details: {
    aud: CommonTypes.Money;
    formatted: string;
    local: CommonTypes.Money;
    price_channel: string;
    price_group: string;
  };
  product_id: string;
  sku: string;
  value: CommonTypes.PriceInUnits;
  url: string;
}

export function segmentPayloadFromEvent(
  event: Event,
  exchangeRateToAUD: CommonTypes.CurrencyConversionRate
): SegmentPayload {
  const product = event.eventData.product.item as Product;
  const priceInUnits = CommonTypes.centsToUnits(product.price.price_in_cents);
  const priceInUnitsAUD = CommonTypes.convertedUnits(priceInUnits, exchangeRateToAUD);
  return {
    ...product,
    brand: 'Bellroy',
    category: product.dimensions['product_type']?.[0] ?? '',
    currency: CurrencyCode.parse(product.price.currency_code),
    image_url: null,
    price: CommonTypes.centsToUnits(product.price.price_in_cents),
    price_details: {
      aud: CommonTypes.moneyifyAUD(priceInUnitsAUD),
      formatted: product.price.formatted,
      local: CommonTypes.moneyify(
        priceInUnits,
        CurrencyCode.parse(product.price.currency_code)
      ),
      price_channel: 'bellroy.com',
      price_group: product.price.price_group,
    },
    product_id: product.sku,
    value: CommonTypes.centsToUnits(product.price.price_in_cents),
    url: product.canonical_url,
  };
}

export default function (eventStream: EventStream) {
  eventStream.addListener('product.view', (event: Event) => {
    if (/products/i.test(document.location.pathname)) {
      pushToGoogleAnalytics(event);
      Segment.push(
        'Product Viewed',
        segmentPayloadFromEvent(event, window.dataLayerInitialValues.exchange_rate_to_aud)
      );
    }
  });
}
