import * as Segment from "@app/javascript/tracking/Segment";

const dialogOpenEvent = new Event("open");

class Modal extends HTMLElement {
  private attrObserver: MutationObserver;
  dialog: HTMLDialogElement | undefined;

  constructor() {
    super();

    this.attrObserver = new MutationObserver((mutations, observer) => {
      mutations.forEach(async (mutation) => {
        if (mutation.attributeName === "open") {
          const dialog = mutation.target as HTMLDialogElement;

          const isOpen = dialog.hasAttribute("open");
          if (!isOpen) return;

          dialog.dispatchEvent(dialogOpenEvent);
        }
      });
    });
  }

  connectedCallback(): void {
    const dialog = this.querySelector("dialog");
    if (!dialog) {
      console.error("Dialog element not found");
      return;
    }
    this.dialog = dialog;

    this.attrObserver.observe(this.dialog, {
      attributes: true,
    });

    this.dialog.addEventListener("open", () => {
      Segment.push("Modal Opened", {
        id: dialog.id,
      });
    });

    let lazySlug = this.dialog.dataset.lazySlug;
    if (lazySlug) {
      this.dialog.addEventListener("open", () => {
        this.renderLazyContent(lazySlug);
      });
    }

    dialog.addEventListener("click", (e) => {
      if (e.target === dialog) {
        dialog.close();
      }
    });
  }

  disconnectedCallback(): void {
    this.attrObserver.disconnect();
  }

  renderLazyContent(slug: string): void {
    let contentContainer = this.dialog?.querySelector("[data-content]");
    if (!contentContainer) return;

    let contentBlock = document.createElement("content-block");
    contentBlock.setAttribute("slug", slug);
    contentContainer.replaceWith(contentBlock);
  }
}

customElements.define("bellroy-modal", Modal);

document.body.addEventListener("click", (e) => {
  const eTarget = e.target as HTMLElement;
  const target = (eTarget.closest("a") || eTarget) as HTMLElement;
  if (target.matches('[data-toggle="modal"]')) {
    e.preventDefault();
    const dialogId = target.dataset.target;
    const dialog = document.querySelector(dialogId) as HTMLDialogElement;
    dialog && dialog.showModal();
  }
});
