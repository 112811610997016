class Accordion extends HTMLElement {
  connectedCallback() {
    this.checkboxes = document.querySelectorAll(
      "input[name='responsible-business-accordion']"
    );
    this.checkboxes.forEach((checkbox) => {
      checkbox.addEventListener("change", () => {
        if (checkbox.checked) {
          this.checkboxes.forEach((otherCheckbox) => {
            if (otherCheckbox !== checkbox) {
              otherCheckbox.checked = false;
            }
          });
        }
      });
    });
  }

  disconnectedCallback() {}
}

customElements.define("bellroy-responsible-business-accordion", Accordion);
