import type { EventStream, Event } from '../../elm/EventStream/js/EventStream';
import * as Segment from "./Segment"

interface PaymentInfoEnteredPayload {
  checkout_id: string
  step: number,
  shipping_method: string | null,
  payment_method: string
}

export function segmentPayloadFromEvent(
  event: Event,
  checkout_id: string,
): PaymentInfoEnteredPayload {
  const shipping_method = sessionStorage.getItem("shipping_method")
  const payment_method = event.eventData.paymentMethod
  const step = event.eventData.step

  return {
    checkout_id: checkout_id,
    step: step,
    shipping_method: shipping_method || "",
    payment_method: payment_method
  }
}
export default function (eventStream: EventStream) {
  eventStream.addListener('checkout.form.payment_method', (event: Event) => {
    const checkout_id = window.dataLayerInitialValues.user.continuation_token
    Segment.push(
      'Payment Info Entered',
      segmentPayloadFromEvent(event, checkout_id)
    );
  })
}
