import * as CurrencyCode from './CurrencyCode';

export interface Money {
  currency_code: CurrencyCode.CurrencyCode;
  numerator: number;
  denominator: number;
}

export type CurrencyConversionRate = number & { readonly __tag: unique symbol };
export type Quantity = number & { readonly __tag: unique symbol };
export type PriceInCents = number & { readonly __tag: unique symbol };
export type PriceInUnits = number & { readonly __tag: unique symbol };

export function moneyifyAUD(amount: PriceInUnits): Money {
  return moneyify(amount, CurrencyCode.CurrencyCode.AUD);
}

export function moneyify(amount: PriceInUnits, currencyCode: CurrencyCode.CurrencyCode): Money {
  const denominator = CurrencyCode.denominator(currencyCode);

  return {
    currency_code: currencyCode,
    numerator: Math.round(amount * denominator),
    denominator: denominator,
  };
}

export function centsToUnits(amount: PriceInCents): PriceInUnits {
  return (amount / 100) as PriceInUnits;
}

export function convertedUnits(amount: PriceInUnits, rate: CurrencyConversionRate): PriceInUnits {
  return (amount * rate) as PriceInUnits;
}

export function lineTotalInCents(amount: PriceInCents, quantity: Quantity): PriceInCents {
  return (amount * quantity) as PriceInCents;
}

export function lineTotalInUnits(amount: PriceInUnits, quantity: Quantity): PriceInUnits {
  return (amount * quantity) as PriceInUnits;
}
