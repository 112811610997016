import BatchedDataLayer from '../../../javascript/lib/Batch/BatchedDataLayer';
import Transactions from './Transactions';
import { PortFromElm, PortToElm } from '../../Common/ElmPorts';

const EVENTSTREAM_VERSION = '2.1.0';


export type Action = {
  transactionId: string,
  action: string,
  data: object
};

export interface Event {
  checkoutMode?: string;
  eventName: string;
  eventTime?: Date;
  eventData: any;
}

type ReceiveEventData = {
  event: Event, dataLayer: any[]
};
type FindEventsData = {
  event: Event
}[];
type GetAvailableEventNamesData = string[];
type ResultData = ReceiveEventData | FindEventsData | GetAvailableEventNamesData;
type PortResult = {
  action: string,
  data: ResultData
};
export type Response = {
  transactionId: string,
  result: {
    error: string
  } | PortResult
};

type Listener = {
  match: string,
  callback: (event: Event, _: Listener) => void,
  id: number
};

export class EventStream {
  private notifyEventStreamAction: (arg: Action) => void;
  private listeners: Listener[] = [];
  private dataLayerService: BatchedDataLayer;

  private transactions = new Transactions({
    onUnresolved: (data) => { this.onUnresolved(data); },
  });

  constructor({ eventStreamActions, eventStreamResponses, dataLayer }: {
    eventStreamActions: PortToElm<Action>,
    eventStreamResponses: PortFromElm<Response>,
    dataLayer: BatchedDataLayer
  }) {
    this.listeners = [];
    this.dataLayerService = dataLayer;
    this.notifyEventStreamAction = eventStreamActions.send;
    eventStreamResponses.subscribe((o) => { this.transactions.resolve(o); });
  }

  private async input<ResultDataVariant extends ResultData>(
    action: string,
    data: object = {}
  ): Promise<ResultDataVariant> {
    const { result } =
      await this.transactions
        .run<{ action: string; data: object; }, PortResult>(this.notifyEventStreamAction, { action, data });
    return result.data as ResultDataVariant;
  }

  private onUnresolved(data) {
    // XXX: This old code will never run because:
    // - eventStreamResponses causes transactions.resolve.
    // - onUnresolved gets the data passed to transactions.resolve, if it failed.
    // - The data produced by eventStreamResponses does not contain a root `data` member.
    //
    // It probably _should_ contain a root `data` member, because it should not forward errors.
    if (data.dataLayer) {
      data.dataLayer.forEach((item) => {
        this.dataLayerService.push(item);
      });
    }
  }

  get DataLayer() {
    return {
      flush: this.dataLayerService.flush,
    };
  }

  toArray() {
    return this.listeners;
  }

  async add(event: Event) {
    const result = await this.input<ReceiveEventData>(
      'receiveEvent',
      {
        eventTime: Date.now(),
        ...event
      }
    );
    if (result.dataLayer) {
      result.dataLayer.forEach((item) => {
        this.dataLayerService.push(item);
      });
    }
    this.listeners
      .filter(({ match }) => match === event.eventName)
      .forEach((listener) => listener.callback(event, listener));
    return result;
  }

  async addListener(a, b) {
    const tmpListener = {
      match: typeof a === 'string' ? a : b,
      callback: typeof a === 'function' ? a : b,
      id: this.listeners.length + 1
    };

    this.listeners.push(tmpListener);
    return tmpListener;
  }

  async removeListener(id: number) {
    this.listeners = this.listeners.filter((l) => l.id != id);
    return id;
  }

  async get(eventName: string) {
    const events = await this.input<FindEventsData>('findEvents', { eventName });
    // XXX: Why do we copy event.eventTime into date?
    return events.map(({ event }) => ({
      event,
      date: new Date(event.eventTime ?? 0),
    }));
  }

  async available() {
    this.input<GetAvailableEventNamesData>('getAvailableEventNames');
  }

  version() {
    return EVENTSTREAM_VERSION;
  }
}
