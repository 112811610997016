const merge = (a, b) => {
  if (
    typeof a.eventDetailed !== 'undefined' &&
    typeof b.eventDetailed !== 'undefined' &&
    a.eventDetailed === b.eventDetailed
  ) {
    a.ecommerce.impressions = a.ecommerce.impressions.concat(b.ecommerce.impressions);
    return a;
  }
  return null;
};

export default merge;
