/* global window */
declare global {
  interface Window {
    dataLayer: object[];
  }
}

const maxQueueSize = 8;
const idleTimeout = 1500;

class Batch<Item> {
  private idleTimer?: number = undefined;
  private queue: Item[] = [];

  push(item: Item): void {
    this.queue.push(item);

    if (this.queue.length >= maxQueueSize) {
      this.flush();
    } else {
      this.flushWhenIdle();
    }
  }

  flush(): void {
    if (document.readyState === 'complete') {
      if (this.queue.length > 0) {
        window.dataLayer.push.apply(window.dataLayer, mergeEvents(this.queue));
        this.queue = [];
      }
    } else {
      this.flushWhenIdle();
    }
  }

  private flushWhenIdle(): void {
    if (this.idleTimer) {
      window.clearTimeout(this.idleTimer);
    }
    this.idleTimer = window.setTimeout(
      () => {
        this.idleTimer = undefined;
        this.flush();
      },
      idleTimeout
    );
  }
}

const batch = new Batch();

const STORAGE_NAME_UNIQUENESS = 'bellroy:datalayer:uniqueness';

let availableMergers = {};

const mergeEvents = (events) => {
  return events.reduce((mergedEvents_, event_) => {
    if (typeof event_ === 'undefined' || typeof event_.event === 'undefined') {
      return mergedEvents_;
    }

    const merge = availableMergers[event_.event];

    if (!merge) {
      mergedEvents_.push(event_);
      return mergedEvents_;
    }

    let isMerged = false;
    mergedEvents_ = mergedEvents_.map((e_) => {
      if (e_.event === event_.event) {
        const mergedEvent = merge(e_, event_);
        if (mergedEvent) {
          isMerged = true;
          return mergedEvent;
        }
      }
      return e_;
    });

    if (!isMerged) {
      mergedEvents_.push(event_);
    }

    return mergedEvents_;
  }, []);
};

const pushIfUnique = (string, callback) => {
  const batchedDataLayerUniqueness = window.localStorage.getItem(STORAGE_NAME_UNIQUENESS);
  const data = batchedDataLayerUniqueness ? JSON.parse(batchedDataLayerUniqueness) : {};

  if (data[string]) {
    return;
  }

  data[string] = new Date().getTime();
  window.localStorage.setItem(STORAGE_NAME_UNIQUENESS, JSON.stringify(data));
  return callback();
};

class BatchedDataLayer {
  constructor({ mergers }) {
    availableMergers = mergers;

    // Flush the queue the page is backgrounded or unloaded.
    window.addEventListener('visibilitychange', () => {
      if (document.visibilityState === 'hidden') {
        this.flush();
      }
    });

    // XXX: Safari does not reliably fire the `visibilitychange` event when the
    // page is being unloaded.
    window.addEventListener('pagehide', () => { this.flush(); });
  }

  push(item) {
    if (typeof item.__unique !== 'undefined' && item.__unique !== false) {
      pushIfUnique(item.__unique, () => {
        batch.push(item);
      });
    } else {
      batch.push(item);
    }

    if (typeof item.__flush !== 'undefined' && item.__flush === true) {
      batch.flush();
    }
  }

  flush() {
    batch.flush();
  }
}

export default BatchedDataLayer;
