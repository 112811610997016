import type { EventStream, Event } from '../../elm/EventStream/js/EventStream';
import * as Segment from './Segment';

export default function (eventStream: EventStream) {
  eventStream.addListener(
    'newsletter.signup',
    (event: Event) => {
      const previousEmail = Segment.getIdentificationEmail();
      const email: string = event.eventData.email;
      if (email && email.length > 3 && email.includes("@")) {
        if (email !== previousEmail) {
          Segment.setIdentificationEmail(email);
          Segment.identify({ email: email });
        }
        Segment.push(
          "Newsletter Subscribed",
          {
            source: event.eventData.source,
            traits: { email: email }
          }
        );
      }
    }
  );
}
