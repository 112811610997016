import type { EventStream, Event } from '../../elm/EventStream/js/EventStream';
import * as Segment from './Segment';

interface SegmentProduct {
  style: string;
  category: string;
  name: string;
  brand: string;
  quantity: number;
  url: string;
  image_url: string;
}

interface SegmentFilter {
  type: string;
  value: string;
}

interface SegmentSort {
  type: string;
  value: string;
}

export interface SegmentPayload {
  list_id: string;
  category: string;
  filters: SegmentFilter[];
  sorts: SegmentSort[];
  products: SegmentProduct[];
}

export function segmentPayloadFromEvent(filters: SegmentFilter[], sort: SegmentSort, products: SegmentProduct[]): SegmentPayload {
  let url = new URL(window.location.href)
  const category = url.pathname.split("/category/")[1]?.split("/")[0] as string

  return {
    list_id: url.href,
    category: category,
    filters: filters,
    sorts: [sort],
    products: products,
  }
}

export default function (eventStream: EventStream) {
  eventStream.addListener('product.list.filter', (event: Event) => {
    const filters = event.eventData.filters.map((filter) => {
      return {
        type: filter.type,
        value: filter.value,
      };
    })

    const sort = {
      type: "sort",
      value: event.eventData.sort,
    };

    const products = event.eventData.products.map((product) => {
      const currencyCode = product.price.currency_code.toUpperCase()
      return {
        style: product.dimensions.style[0],
        category: product.dimensions.product_type[0],
        name: product.name,
        brand: "Bellroy",
        quantity: 1,
        url: product.canonical_url,
        image_url: `https://bellroy-product-images.imgix.net/bellroy_dot_com_gallery_image/${currencyCode}/${product.sku}/0`
      };
    })

    Segment.push('Product List Filtered', segmentPayloadFromEvent(filters, sort, products));
  });
}
