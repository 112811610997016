import type { EventStream, Event } from '../../elm/EventStream/js/EventStream';
import * as Segment from './Segment';


type CommonPayloadFields = {
  shipping_to_country_code: string;
  cookie_consent_format: string;
};

export type SegmentNameAndPayload = {
  name: string;
  payload: CommonPayloadFields | CommonPayloadFields & { cookie_consent: string };
};


export function makeSegmentNameAndPayload(event: Event): SegmentNameAndPayload | null {
  if (event?.eventData?.category !== 'cookie_consent') {
    return null;
  }

  const payloadCommon = {
    shipping_to_country_code: event.eventData.shippingToCountryCode,
    cookie_consent_format: event.eventData.cookieConsentFormat
  }

  const eventPayloads = {
    "banner_shown": {
      name: 'Cookie Consent Banner Displayed',
      payload: payloadCommon
    },
    "granted": {
      name: 'Cookie Consent Updated',
      payload: {
        ...payloadCommon,
        cookie_consent: 'granted'
      }
    },
    "denied": {
      name: 'Cookie Consent Updated',
      payload: {
        ...payloadCommon,
        cookie_consent: 'denied'
      }
    }
  }

  if (Object.keys(eventPayloads).includes(event.eventData.action)) {
    return eventPayloads[event.eventData.action]
  }

  return null;
}

export default function (eventStream: EventStream) {
  eventStream.addListener('generic.event', (event: Event) => {
    const segmentNameAndPayload = makeSegmentNameAndPayload(event);
    if (segmentNameAndPayload) {
      Segment.push(segmentNameAndPayload.name, segmentNameAndPayload.payload);
    }
  });
}
