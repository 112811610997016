import * as Sentry from '@sentry/browser';

export default class SentryFirstSeenFilter {
  private eventsSeen: Set<string>;
  constructor() {
    this.eventsSeen = new Set();
  }
  isFirstTimeSeen(event: Sentry.Event): boolean {
    const exceptionKey =
      event.exception &&
      event.exception.values &&
      event.exception.values
        .map(function (exception) {
          return exception.value;
        })
        .join();
    const eventKey = event.message || exceptionKey;

    if (!eventKey) {
      return true;
    } else if (!this.eventsSeen.has(eventKey)) {
      this.eventsSeen.add(eventKey);
      return true;
    } else {
      return false;
    }
  }
}
