const intersectionCallback = (entries) => {
  entries.forEach((entry) => {
    const appearClass = entry.target.getAttribute("appear");
    const disappearTopClass = entry.target.getAttribute("disappear-top");
    const disappearBottomClass = entry.target.getAttribute("disappear-bottom");

    if (entry.isIntersecting) {
      if (entry.intersectionRatio >= 0.5) {
        entry.target.classList.add(appearClass);
        entry.target.classList.remove(disappearTopClass, disappearBottomClass);
      }
    } else {
      entry.target.classList.remove(appearClass);
      if (entry.boundingClientRect.bottom <= 0) {
        entry.target.classList.add(disappearTopClass);
      } else {
        entry.target.classList.add(disappearBottomClass);
      }
    }
  });
};

const intersectionObserver = new IntersectionObserver(intersectionCallback, {
  rootMargin: "50px 0px",
  threshold: [0, 0.5, 1],
});

customElements.define(
  "div-appear",
  class extends HTMLElement {
    constructor() {
      super();
    }
    connectedCallback() {
      intersectionObserver.observe(this);
    }
    disconnectedCallback() {
      intersectionObserver.unobserve(this);
    }
  }
);
