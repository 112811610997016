class Tabs extends HTMLElement {
  tabs = [];
  panels = [];
  currentTab = null;

  constructor() {
    super();
    this.boundTabClick = this.onTabClick.bind(this);
  }

  get showAll() {
    return window.matchMedia
      ? window.matchMedia("(min-width:768px)").matches
      : false;
  }

  onTabClick(evt) {
    evt.preventDefault();
    if (!this.showAll) {
      this.selectTab(evt.currentTarget);
    }
  }

  selectTab(tab) {
    this.currentTab = tab;
    this.tabs.forEach((tab) => {
      tab.setAttribute("aria-selected", "false");
    });
    tab.setAttribute("aria-selected", "true");
    this.panels.forEach(this.hidePanel);
    const activePanel = this.querySelector(tab.getAttribute("href"));
    this.showPanel(activePanel);
  }

  setupShowAll() {
    this.tabs.forEach((tab) => {
      tab.setAttribute("aria-selected", "true");
      tab.addEventListener("click", this.boundTabClick);
    });
    this.panels.forEach(this.showPanel);
  }

  setupShowIndividual() {
    this.tabs.forEach((tab) => {
      tab.addEventListener("click", this.boundTabClick);
    });
  }

  setup() {
    if (this.showAll) {
      this.setupShowAll();
    } else {
      this.setupShowIndividual();
      this.selectTab(this.currentTab ? this.currentTab : this.tabs[0]);
    }
  }

  showPanel(panel) {
    if (!!panel) {
      panel.removeAttribute("hidden");
      panel.setAttribute("aria-hidden", "false");
    }
  }

  hidePanel(panel) {
    if (!!panel) {
      panel.setAttribute("hidden", "true");
      panel.setAttribute("aria-hidden", "true");
    }
  }

  connectedCallback() {
    this.tabs = this.querySelectorAll("[role='tab']");
    this.panels = this.querySelectorAll("[role='tabpanel']");

    this.setup();

    window.addEventListener("resize", () => {
      this.setup();
    });
  }
}

customElements.define("bellroy-responsible-business-tabs", Tabs);
